import * as React from "react";
import Layout from "../Layout/MasterLayout/MasterLayout";

import Button from "../components/Button/Button";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SEO from "../components/SEO/SEO";

import imgRematriculas from "../assets/images/rematriculas/rematriculas.png";

import '../components/css/images.css'

export default function Rematriculas() {
	return (
		<Layout>
			<SEO title="Rematrículas" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol
						col={12}
						md={6}
						className="order-1 order-sm-0 my-auto px-3 px-md-2 pb-4"
					>
						<h1 className="pageTitle fgsecondary-gradient d-none d-md-block">
							rematrículas 2025
						</h1>
						<h1
							className="pageTitle fgsecondary-gradient d-block d-md-none"
							style={{ fontSize: "3.7em" }}
						>
							rematrículas 2025
						</h1>
						<p>
							{/* indique um amigo e ganhe <b>20% de desconto</b> em duas
							mensalidades caso ele se matricule*/}
						</p>
						<Button
							type="comercial"
							to="https://portal.feevale.br/FrameHTML/web/app/edu/portalEducacional/login/"
							text="Faça sua rematrícula"
						/>
						<Button
							type="comercial mt-3"
							to="https://www.feevale.br/s/conteudo/a2cb6996-5f6a-48d5-b33f-e10abc386cd3/Tutorial_Rematricula_RM_-_Escola_de_Aplicacao_-_Atualizado_2024.pdf"
							text="Acesse o tutorial de rematrícula"
						/>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgRematriculas} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pb-3">
						<h2 className="fgterciary-gradient">
							confira os valores das etapas de aprendizagem
						</h2>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="py-2">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/educacao-infantil"
							text="Educação Infantil"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-2 py-md-2">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/ensino-fundamental"
							text="Ensino Fundamental"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="pb-2">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/ensino-medio"
							text="Ensino Médio"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-2">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/cursos-tecnicos"
							text="Cursos Técnicos"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<Button
							type="ensino"
							to="/a-escola/para-o-estudante"
							text="Clique aqui para mais informações para estudantes"
						/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
